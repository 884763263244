import React from 'react'
import './Top.css'

const Top = () => {
  return (
    <div className='head'>
    <section>
        <h1>Sanagram</h1>
        <span>
            <img alt='love' src='/images/icons8-love-50.png' />
            <img alt='love' src='/images/message.png' /> 
            <small id='likes'>5029</small>
            <small id='text'>218</small>
        </span>
    </section>
        
    </div>
  )
}

export default Top