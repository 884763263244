import React from 'react'
import { useState } from 'react'

const Tokenomics = () => {
    const [liked, setLiked] = useState(false)
    function like(){
        setLiked(!liked)
      }
  return (
    <section className='comp'>
    <main className='top'>
      <div className='profile'>
      <img alt='' src='/images/story1.jpg' />
      </div>
      <div>
      <h1> 
        Tokenomics 
      </h1>
      <small></small>
      </div>
    </main>
    <article className='bottom'>
    <main className='imagesSLides' id='nomics' style={{backgroundColor: 'black', color: 'white', display: 'flex', flexDirection:'column', justifyContent:'center', textAlign:'center', alignItems:'center', fontSize: '16px'}} >
    <span>
        <h1>$Sana</h1>
    </span>
    <span>
        {/* <h1> Token supply</h1> */}
        <h1>Supply - 1,000,000,000  </h1>
    </span>
    <span>
        <h1>Liquidity Locked and Burned</h1>
    </span>
    {/* <span>
        <h1>Community Takeover</h1>
    </span> */}
    </main>
      <div>
        <span className='reactions'>
        <img alt="" src= {liked ? "/images/loved.png" : "/images/icons8-love-50.png"}  onClick={like}  />
          <img alt='' src='/images/icons8-comment-50.png'/>
          <img alt='' src='/images/icons8-send-50.png'/>
        </span>
      </div>
      <small>
        <img src='' alt='' />
        Liked by <b>Vogue</b> and <b>4,400,700 others</b>
      </small>
      <p>
        <b>CA: </b> hbGFvcZeiAwcP72arwnixPJ1eRFbzgVUGfZYPnaor9V  
      </p>
    </article>
  </section>
  )
}

export default Tokenomics