import React from "react";
import './Footer.css'

const Footer = () => {
  return (
    <section className="comp">
  
      <div className="socials">
          <h1>Socials</h1>
          <span className="reactions">
            <a href="https://x.com/SanaOnSolana?t=pTCEjGFL0aQT83dIScVkNA&s=09">
              <img alt="" src="/images/twitter.png" />
            </a>
            <a href="https://t.me/sanaminatozakisol">
              <img alt="" src="/images/telegram.png" />
            </a>
          </span>
        </div>
    </section>
  );
};

export default Footer;
