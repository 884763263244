import React from 'react'
import './Nav.css'

const Nav = () => {
  return (
        <div className='navStory'>
        <main>
        <img alt='' src='/images/story1.jpg' />
        <p>My Story</p>
        </main>
        <main>
        <img alt='' src='/images/story2.jpg' />
        <p>Sana</p>
        </main>
        <main>
        <img alt='' src='/images/story3.jpg'/>
        <p>Twice</p>
        </main>
        <main>
        <img alt='' src='/images/story4.jpg' />
        <p>Vogue</p>
        </main>
        {/* <main>
        <img alt='' src='/images/st4.jpg' />
        <p>Johny</p>
        </main> */}
{/*               
              <img alt='' src='/images/st2.jpg' />
              <img alt='' src='/images/st3.jpg' />
              <img alt='' src='/images/st4.jpg' />
              <img alt='' src='/images/st1.jpg' /> */}
            </div>
  )
}

export default Nav