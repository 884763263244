import React from 'react'
import { useState } from 'react'

const Middle = () => {
    const [liked, setLiked] = useState(false)
    function like(){
        setLiked(!liked)
      }
  return (
    <section className="comp">
    <main className="top">
      <div className="profile">
        <img alt="" src="/images/story1.jpg" />
      </div>
      <div>
        <h1>m.by__sana</h1>
        <small></small>
      </div>
    </main>
    <article className="bottom">    
        <div className="imagesSLides">
        <div>
        <img alt="" src="/images/sana1.jpg" className="mainimg" />
        </div>
        <div>
        <img alt="" src="/images/sana2.jpg" className="mainimg" />
        </div>
        <div>
        <img alt="" src="/images/sana3.jpg" className="mainimg" />
        </div>
        <div>
        <img alt="" src="/images/sana4.jpg" className="mainimg" />
        </div>
        <div>
        <img alt="" src="/images/sana5.jpg" className="mainimg" />
        </div>
        {/* <img alt="" src="/images/sana2.jpg" className="mainimg" />
        <img alt="" src="/images/sana3.jpg" className="mainimg" />
        <img alt="" src="/images/sana4.jpg" className="mainimg" />
        <img alt="" src="/images/sana5.jpg" className="mainimg" /> */}
      </div>
      <div>
        <span className="reactions">
        <img alt="" src= {liked ? "/images/loved.png" : "/images/icons8-love-50.png"}  onClick={like}  />
          <img alt="" src="/images/icons8-comment-50.png" />
          <img alt="" src="/images/icons8-send-50.png" />
        </span>
      </div>
      <small>
        <img src="" alt="" />
        Liked by <b>Twice</b> and <b>640,700 others</b>
      </small>
      <p>
        <b>m.by__sana</b> Less perfection more audacity 
      </p>
    </article>
  </section>
  )
}

export default Middle