import React from 'react'
import { useState } from 'react'

const Narrative = () => {
    const [liked, setLiked] = useState(false)
    function like(){
        setLiked(!liked)
      }
  return (
    <section className='comp'>
    <main className='top'>
      <div className='profile'>
      <img alt='' src='/images/story1.jpg' />
      </div>
      <div>
      <h1>
        Narrative  
      </h1>
      <small>Solana</small>
      </div>
    </main>
    <article className='bottom'>
    <main className='imagesSLides' id='narr' style={{backgroundColor: 'black', color: 'white', display: 'flex', flexDirection:'column', justifyContent:'center', lineHeight:'30px', textAlign:'center', alignItems:'center', fontSize: '14px'}}>
        <h2>
        Sana Minatozaki, born December 29, 1996, known mononymously as Sana is a Japanese singer based in South Korea. She is a member of the South Korean girl group Twice, formed in 2015 by JYP Entertainmen, & Co-managed by the Solana Ecosystem.
        </h2>
        <button><a href='/'>Chart</a></button>
    </main>
      <div>
        <span className='reactions'>
        <img alt="" src= {liked ? "/images/loved.png" : "/images/icons8-love-50.png"}  onClick={like}  />
          <img alt='' src='/images/icons8-comment-50.png'/>
          <img alt='' src='/images/icons8-send-50.png'/>
        </span>
      </div>
      <small>
        <img src='' alt='' />
        Liked by <b>Jennie</b> and <b>840,280 others</b>
      </small>
    </article>
  </section>
  )
}

export default Narrative