import React from "react";
import "./divorced.css";
import { useEffect, useRef, useState } from "react";
import vid from '../../assets/vid2.mp4'
// import Vid from '../../../public/videos/vid3.mp4'

const Divorceddad = () => {
  const [liked, setLiked] = useState(false)
  const videoRef = useRef(null);

  useEffect(() => {
    const videoElement = videoRef.current;
  
    const playVideo = () => {
      if (videoElement.paused) {
        // Check if the video is paused before playing
        videoElement.play().catch((error) => {
          console.error('Autoplay failed:', error.message);
        });
      }
      videoElement.removeAttribute('controls');
    };
  
    // Play video initially when the component mounts
    playVideo();
  
    const handleInteraction = () => {
      // Handle user interaction event (optional)
      playVideo();
      videoElement.muted = false;
    };
  
    // Event listener to handle user interaction (optional)
    document.addEventListener('click', handleInteraction);
  
    const options = {
      root: null, // Observe the entire viewport
      threshold: 0.5, // Play when 50% or more of the video is visible
    };
  
    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          playVideo(); // Play the video
        } else {
          videoElement.pause(); // Pause on leaving view
        }
      });
    };
  
    const observer = new IntersectionObserver(handleIntersection, options);
    observer.observe(videoElement);
  
    // Cleanup function on component unmount
    return () => {
      observer.unobserve(videoElement);
      document.removeEventListener('click', handleInteraction);
    };
  }, []);
  
  
  

  function like(){
    setLiked(!liked)
  }
  return (
    <section className="comp">
      <main className="top">
        <div className="profile">
          <img alt="" src="/images/story1.jpg" />
        </div>
        <div className="topLcation">
          <h1>m.by__sana</h1>
          <small>Solana</small>
        </div>
      </main>
      <article className="bottom">
      <div className="vids">
      <div>
      <video src='/videos/sana.webm' ref={videoRef} loop className="mainVid" ></video> 
      </div>
      </div>
        <div>
          <span className="reactions">
            <img alt="" src= {liked ? "/images/loved.png" : "/images/icons8-love-50.png"}  onClick={like}  />
            <img alt="" src="/images/icons8-comment-50.png" />
            <img alt="" src="/images/icons8-send-50.png" />
          </span>
        </div>
        <small>
          <img src="" alt="" />
          Liked by <b>Jihyo</b> and <b>{1},{135},{liked ? 700 + 1 : 700} others</b>
        </small>
        <p>
          <b>m.by__sana</b> Sexiest girl on Solana 
        </p>
      </article>
    </section>
  );
};

export default Divorceddad;
