import React from 'react'
import './Reels.css'

const Reels = () => {
  return (
    <section className='reels'>
        <h1>
            Suggested Reels 
        </h1>
        <main>
            <div>
                <video src='/videos/gif3.mp4' className='gifs' autoPlay muted loop ></video>
            </div>
            <div>
                <video src='/videos/gif1.mp4' className='gifs' autoPlay muted loop ></video>
            </div>
            <div>
                <video src='/videos/reel2.mp4' autoPlay muted loop ></video>
            </div>
        </main>
    </section>
  )
}

export default Reels